import axios from "@/lib/axios";

async function fetchUserLocations(orgId) {
  try {
    const response = await axios.get(`/accounts/v1/location/org/${orgId}`);
    if (response) {
      const { data } = response;
      return data;
    }
  } catch (error) {
    return null;
  }
}

export default {
  fetchUserLocations,
};
