import BaseProxy from "../../base-proxy";

// UTILS
import notification from "@/utils/notifications";

// CONSTANTS
import NOTIFICATION_CONSTANTS from "@/constants/notification-type-constants";

export default class GetSimpatraServiceProxy {
  async execute(query = "", queryParams = {}, additionalConfig, axiosConfig) {
    const baseProxy = new BaseProxy();
    const backendUrl = await baseProxy.getSimpatraServiceUrl();
    const endpointUrl = `${backendUrl}/${query}`;
    try {
      return await baseProxy.getSimpatraServiceData(endpointUrl, queryParams, axiosConfig);
    } catch (error) {
      if (additionalConfig && additionalConfig.notificationParams) {
        const { notificationParams } = additionalConfig;
        if (!notificationParams.hideErrorNotif) {
          notification(
            NOTIFICATION_CONSTANTS.WARNING,
            notificationParams && notificationParams.errorNotificationMsg
              ? notificationParams.errorNotificationMsg
              : error.response.data.message
          );
        }
        if (notificationParams && notificationParams.returnError) {
          return error && error.response;
        }
      }
      return;
    }
  }
}
