import BaseProxy from "../../base-proxy";

// UTILS
import notification from "@/utils/notifications";

// CONSTANTS
import NOTIFICATION_CONSTANTS from "@/constants/notification-type-constants";

export default class GetSimpatraLabResultProxy {
  async execute(query = "", hideErrorMsg = false) {
    const baseProxy = new BaseProxy();
    const backendUrl = await baseProxy.getSimpatraLabResultUrl();
    const endpointUrl = `${backendUrl}/${query}`;
    try {
      return await baseProxy.getSimpatraLabResultData(endpointUrl);
    } catch (error) {
      if (!hideErrorMsg) {
        notification(NOTIFICATION_CONSTANTS.WARNING, error.response.data.message);
      }
      return null;
    }
  }
}
