import moment from "moment";

export const monthDayYear = (date) => {
  return moment(date).format("MMMM D, YYYY");
};

export const monthDay = (date) => {
  return moment(date).format("MMMM D");
};

export const yearMonthDay = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const monthDayYearTime = (date) => {
  return moment(date).format("MM/DD/YY, h:mm a");
};

export const MMDDYYYY = (date) => {
  return moment(date).format("MM-DD-YYYY");
};

export const timeSince = (date) => {
  return moment.utc(date).fromNow();
};

export const yearsOld = (date) => {
  return moment().diff(date, "years");
};

export const thisDay = (date) => {
  return moment(date).format("LL");
};

export const nextDay = (date) => {
  return moment(date).add(1, "days").format("LL");
};
