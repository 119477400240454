import BaseProxy from "../../base-proxy";

// UTILS
import notification from "@/utils/notifications";

// CONSTANTS
import NOTIFICATION_CONSTANTS from "@/constants/notification-type-constants";

export default class DeleteSimpatraServiceProxy {
  async execute(query = "", config) {
    const { queryParams = {}, notificationParams } = config;
    const baseProxy = new BaseProxy();
    const backendUrl = await baseProxy.getSimpatraServiceUrl();
    const endpointUrl = `${backendUrl}/${query}`;
    try {
      const response = await baseProxy.deleteSimpatraServiceData(endpointUrl, queryParams);
      if (notificationParams && notificationParams.displaySuccessMessage) {
        notification(NOTIFICATION_CONSTANTS.SUCCESS, notificationParams && notificationParams.successNotificationMsg);
      }
      return response;
    } catch (error) {
      notification(
        NOTIFICATION_CONSTANTS.WARNING,
        notificationParams && notificationParams.errorNotificationMsg
          ? notificationParams.errorNotificationMsg
          : error.response.data.message
      );
      if (notificationParams && notificationParams.returnError) {
        return error && error.response;
      }
      return {};
    }
  }
}
