import BaseProxy from "../../base-proxy";

// UTILS
import notification from "@/utils/notifications";

// CONSTANTS
import NOTIFICATION_CONSTANTS from "@/constants/notification-type-constants";

export default class PutSimpatraServiceProxy {
  async execute(query = "", payload, notificationMsg) {
    const baseProxy = new BaseProxy();
    const backendUrl = await baseProxy.getSimpatraServiceUrl();
    const endpointUrl = `${backendUrl}/${query}`;
    try {
      const response = await baseProxy.putSimpatraServiceData(endpointUrl, payload);
      if (notificationMsg) {
        notification(NOTIFICATION_CONSTANTS.SUCCESS, notificationMsg);
      }
      return response;
    } catch (error) {
      notification(NOTIFICATION_CONSTANTS.WARNING, error.response.data.message);
      return;
    }
  }
}
